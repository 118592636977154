import * as React from 'react'

//import {Link} from 'gatsby'
import Layout from '../components/layout/layout'
import {StaticImage} from 'gatsby-plugin-image'

const pageTitle = "International Management Association IMA :: Company List"


const CompanyListPage = () => {
    return (
        <Layout pageTitle={pageTitle}>

            <div className="span-23 last" style={{ overflow: "hidden", marginBottom: "60px", marginTop: "10px" }}>
                <div className="span-1">&nbsp;</div>
                <div className="span-22 last" id="breadcrumb">
                    <a href="/">Home</a> &gt; Company List
                </div>
                <div className="span-1">&nbsp;</div>
                <div className="span-6">


                    <img
                        src="/media/imagehotlink/2014/04/07/NLP_1_a.jpg"
                        border="0" style={{marginBottom: "20px"}}/>
                    <img
                        src="/media/imagehotlink/2014/04/07/EDUA_6_a.jpg"
                        border="0" style={{marginBottom: "20px"}}/>
                    <img
                        src="/media/imagehotlink/2014/04/07/Classroom_a_1.jpg"
                        border="0" style={{marginBottom: "20px"}}/>
                    <img
                        src="/media/imagehotlink/2014/04/07/2011_photoa_Living_Biz_a_1.jpg"
                        border="0" style={{marginBottom: "20px"}}/>
                    <img
                        src="/media/imagehotlink/2014/04/07/20050417-038_a_1.jpg"
                        border="0" style={{marginBottom: "20px"}}/>


                </div>
                <div className="span-15">

                    <p className="darkpurple"><b>COMPANY LIST</b></p>

                    <p>Our members work for a variety of companies including many international
                        corporations, public listed companies, government and semi government
                        organizations, schools, universities, hospitals, and social services center etc. </p>

                    <p>The following is a partial list of companies that our members are working for:</p>

                    <table width="500" border="0" cellSpacing="0" cellPadding="0" id="companylist">
                        <tbody>
                        <tr>
                            <td width="250">A Plus Consulting Ltd</td>

                            <td width="250">Lai Tak Enterprise</td>
                        </tr>
                        <tr>
                            <td>Asahi Group Co. Ltd</td>
                            <td width="250">Latham &amp; Watkins</td>
                        </tr>

                        <tr>
                            <td>Bank of America</td>
                            <td>LG Electronics HK</td>
                        </tr>
                        <tr>
                            <td>Be A Lady Limited</td>
                            <td>M&amp;C toy Centre Ltd</td>

                        </tr>
                        <tr>
                            <td>Beaufour Ipsen International</td>
                            <td>Maersk Logistics HK Ltd</td>
                        </tr>
                        <tr>
                            <td>Belilios Public School</td>

                            <td>Mandarin Oriental HK</td>
                        </tr>
                        <tr>
                            <td>BOC Gases</td>
                            <td>Manulife</td>
                        </tr>
                        <tr>

                            <td>Cathay Pacific Airways Ltd</td>
                            <td>Maryknoll Secondary School</td>
                        </tr>
                        <tr>
                            <td>Center for Effective Leadership</td>
                            <td>MassMutual Asia Ltd</td>
                        </tr>

                        <tr>
                            <td>China Everbright Holdings Co. Ltd</td>
                            <td>Million Knit Knitting Factory</td>
                        </tr>
                        <tr>
                            <td>Christian Family Service Centre</td>
                            <td>Miramar Group</td>

                        </tr>
                        <tr>
                            <td>Compass Engineering Ltd</td>
                            <td>Mitsui Sumitomo Insurance Co. Ltd</td>
                        </tr>
                        <tr>
                            <td>Cotton Council International</td>

                            <td>NEC Hong Kong Ltd</td>
                        </tr>
                        <tr>
                            <td>Cyber Action Technology</td>
                            <td>New World B Kinghan</td>
                        </tr>
                        <tr>

                            <td>Danica Ltd</td>
                            <td>Nippon Paint (China) Co. Ltd</td>
                        </tr>
                        <tr>
                            <td>DBS Bank (HK) Ltd</td>
                            <td>Orient Poser Home Network Ltd</td>
                        </tr>

                        <tr>
                            <td>Defond Electrical Ind. Ltd</td>
                            <td>PCCW HK Ltd</td>
                        </tr>
                        <tr>
                            <td>DeFond Industrial Co. Ltd</td>
                            <td>Red Box Toy Factory Ltd</td>

                        </tr>
                        <tr>
                            <td>Delifrance (HK) Ltd</td>
                            <td>Right Management China</td>
                        </tr>
                        <tr>
                            <td>DHL Aviation (HK) Ltd.</td>

                            <td>Salop company Ltd</td>
                        </tr>
                        <tr>
                            <td>Early Light Industrial Co. Ltd</td>
                            <td>San Fong Seng Const. &amp; Eng. Co. Ltd</td>
                        </tr>

                        <tr>
                            <td>Elex Elevator Ltd</td>
                            <td>Schmidt &amp; Co (HK) Ltd</td>
                        </tr>
                        <tr>
                            <td>Energizer HK Ltd</td>

                            <td>SmarTone Mobile Communication Ltd</td>
                        </tr>
                        <tr>
                            <td>Everwin Holding Ltd</td>
                            <td>State Street Bank and Trust</td>
                        </tr>
                        <tr>

                            <td>Ewig Group</td>
                            <td>Sun Hung Kai Properties Ltd</td>
                        </tr>
                        <tr>
                            <td>Eyston Company Ltd.</td>
                            <td>Sunlex Development Ltd</td>
                        </tr>
                        <tr>
                            <td valign="top">Four Seasons Hotel Macao, Cotai Strip</td>
                            <td></td>
                        </tr>

                        <tr>
                            <td valign="top">Furama (Shenyang) Co. Ltd</td>
                            <td>Sunshine Traffic Engineering &amp; Mgmt Co. Ltd</td>
                        </tr>
                        <tr>
                            <td>GDH Ltd</td>

                            <td>Synergies Management Service Limited</td>
                        </tr>
                        <tr>
                            <td>GEW Corporation Ltd</td>
                            <td>Takson Holding Ltd</td>
                        </tr>
                        <tr>

                            <td>GP Electronics (HK) Ltd</td>
                            <td>Teledirect</td>
                        </tr>
                        <tr>
                            <td>Hill Group Int'nl;</td>
                            <td>Top Degree Technology</td>
                        </tr>

                        <tr>
                            <td>HK Airport Authority</td>
                            <td>Top One Travel Agency</td>
                        </tr>
                        <tr>
                            <td>HK Jockey Club</td>
                            <td>Total Lubricants HK Ltd</td>

                        </tr>
                        <tr>
                            <td>HK Police Force</td>
                            <td>Tse Sui Luen Jewellery</td>
                        </tr>
                        <tr>
                            <td>HK Polytechnic University</td>

                            <td>Union Rich Plastic Factory Ltd</td>
                        </tr>
                        <tr>
                            <td>Hospital Authority</td>
                            <td>University of HK/Space</td>
                        </tr>
                        <tr>

                            <td>HSBC</td>
                            <td>Virgin Atlantic Airways</td>
                        </tr>
                        <tr>
                            <td>ICBC (Asia) Ltd</td>
                            <td>VXL Capital Ltd</td>
                        </tr>

                        <tr>
                            <td>International SOS (HK) Ltd</td>
                            <td>Wallem Shipmanagement Ltd</td>
                        </tr>
                        <tr>
                            <td>IVE</td>
                            <td>Wha Shing Toys Co. Ltd</td>

                        </tr>
                        <tr>
                            <td>JDX Company Ltd.</td>
                            <td>Winpo Industries Ltd</td>
                        </tr>
                        <tr>
                            <td>Johnson &amp; Szeto Co. Ltd</td>

                            <td>Word Race Paper International Ltd</td>
                        </tr>
                        <tr>
                            <td>Johnson Controls HK Ltd</td>
                            <td>Worldwide Package Travel Services Ltd</td>
                        </tr>
                        <tr>

                            <td>Katax CPA Ltd</td>
                            <td>WP Holdings Ltd</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
                <div className="span-1 last">&nbsp;</div>
            </div>

        </Layout>
    )
}
// Step 3: Export your component
export default CompanyListPage
